import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import phoneForMobile from "../assets/luupli-app.png";
import phone from "../assets/luupli-appM.png";

import Apple from "../assets/App Store.svg";
import AppleMobile from "../assets/AppStoreMobile.svg";
import Google from "../assets/GoogleWeb.svg";
import GoogleMobile from "../assets/Google Play.svg";

import "../index.css";
import { isIOS } from "react-device-detect";

const Landing = ({ title }) => {

  const termsAndConditions =
    process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_TERMS_LINK_PROD
      : process.env.REACT_APP_TERMS_LINK_DEV;

  const privacyPolicy =
    process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_PRIVACY_PROD
      : process.env.REACT_APP_PRIVACY_DEV;
  return (
    <Fragment>
      <Helmet>
        <title>Home | luupli</title>
        <meta
          name="description"
          content="Social media is unfair and broken. Join the movement trying to set things right."
        />
      </Helmet>
      <div className="bg-stone-50 max-md:pl-5">
        <div className="gap-0 flex flex-col md:ml-7 xl:ml-12 md:flex-row items-stretch">
          <div className="flex flex-col items-stretch w-[50%] max-md:w-full">
            <div className="flex flex-col items-stretch">
              <div className="text-zinc-950 lg:text-[3.00rem] xl:text-[3.50rem] mt-5 md:mt-24 leading-[60px] font-['Inter'] font-extrabold tracking-tighter max-md:max-w-full text-[2.5rem] max-md:leading-10">
                Social media is unfair and broken
              </div>
              <div className="text-gray-700 text-[1.09375rem] md:text-[1.00rem] xl:text-[1.25rem] font-['Inter'] font-normal leading-6 md:mt-4 tracking-tight mt-2 max-md:max-w-full">
                Join the movement trying to set things right. <br /> Try our
                BETA version now
              </div>
              <div className="flex justify-start items-start w-[100%] gap-6">
                <a
                  href="https://apps.apple.com/gb/app/luupli/id6463861554"
                  className="pt-5 hidden md:block"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Apple} alt="apple button" className="w-36 xl:w-56" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.dev.luupli.uat&hl=en_GB"
                  className="pt-5 hidden md:block"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Google} alt="Google button" className="w-36  xl:w-56" />
                </a>
                {isIOS ? (
                  <a
                    href="https://apps.apple.com/gb/app/luupli/id6463861554"
                    className="pt-5 block md:hidden"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={AppleMobile}
                      alt="apple button"
                      className="w-48"
                    />
                  </a>
                ) : (
                  <a
                    href="https://play.google.com/store/apps/details?id=com.dev.luupli.uat&hl=en_GB"
                    className="pt-5 block md:hidden"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={GoogleMobile}
                      alt="apple button"
                      className="w-48"
                    />
                  </a>
                )}
              </div>

              <div className="text-black text-[0.8375rem] font-['Inter'] tracking-tight xl:w-[90%] md:mt-4">
                <p className="mt-5">
                  By choosing to download our app, you also agree to our{" "}
                  <span className="cursor-pointer underline">
                    <a
                      href={termsAndConditions}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-black"
                    >
                      terms and conditions
                    </a>
                  </span>
                  , including our{" "}
                  <a
                    href={privacyPolicy}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-black cursor-pointer"
                  >
                    privacy policy
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center w-[60%] md:[60%] xl:w-[60%] h-[70%] md:mt-10">
            <img
              loading="lazy"
              src={phone}
              className="hidden md:block aspect-[1.30] object-contain object-center w-full overflow-hidden grow"
              alt="Broken Phone"
            />
            <img
              loading="lazy"
              src={phoneForMobile}
              className="md:hidden w-full ml-16 justify-center items-center scale-90 mb-10"
              alt="Broken Phone"
            />
          </div>
        </div>
      </div>

    </Fragment>
  );
};

export default Landing;
